import React, { useEffect, useState } from "react"
import gsap from "gsap"

import styled from "styled-components"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"
import { Link } from "gatsby"

import Logo from "../images/logo.png"
import favicon16 from "../images/favicon16.png"
import favicon32 from "../images/favicon32.png"
import favicon64 from "../images/favicon64.png"
import Facebook from "../images/facebook.png"
import Ig from "../images/ig.png"

import MOMO1 from "../images/MOMO1.jpg"
import MOMO2 from "../images/MOMO2.jpg"
import MOMO3 from "../images/MOMO3.jpg"

import LeftArrow from "../images/leftArrow.png"
import RightArrow from "../images/rightArrow.png"
import CloseImg from "../images/close.png"

import MomoLeft from "../images/MomoLeft.png"
import MomoRight from "../images/MomoRight.png"

import Menu from "../images/new-momo-menu.jpg"

import LandingBanner from "../images/landing.png"
import DinnerBanner from "../images/dinner1.png"
import LunchBanner from "../images/lunch1.png"
import ConceptBanner from "../images/concept.png"

const simpleParallax =
  typeof window !== `undefined` ? require("simple-parallax-js") : null

const Blockade = styled.div`
  width: 80vw;
  height: 80vh;
  background-color: rgba(0, 0, 0, 0);
  position: fixed;
  top: 10vh;
  left: 10%;
  z-index: 10;
  display: ${props => (props.removeBlock ? "none" : "flex")};
  align-items: center;
  flex-direction: column;
  overflow-y: scroll;

  @media (max-width: 950px) {
    width: 90vw;
    left: 5vw;
  }
`

const BackContainer = styled.div`
  width: 80vw;
  position: fixed;
  left: 10%;
  top: 4vh;
  display: flex;
  box-sizing: border-box;
  z-index: 10;
  display: ${props => (props.removeBlock ? "none" : "flex")};

  @media (max-width: 600px) {
    width: 90vw;
    left: 5vw;
  }
`

const BackButton = styled.button`
  cursor: pointer;
  font-family: "OpenSans-SemiBold";
  color: black;
  font-size: 18px;
  text-decoration: none;
  outline: none;
  border: none;
  background: white;
  padding: 5px 30px;
  border-radius: 10px;

  @media (max-width: 600px) {
    font-size: 12px;
    padding: 2px 20px;
  }
`

const MenuImg = styled.img`
  width: 100%;
`

const BlockadeOverlay = styled.div`
  width: 100vw;
  min-height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 8;
  background: rgba(0, 0, 0, 0.8);
  display: ${props => (props.removeBlock ? "none" : "flex")};
`

const OrderTypeContainer = styled.div`
  position: fixed;
  z-index: 10;
  width: 400px;
  height: 300px;
  left: calc(50vw - 200px);
  top: calc(50vh - 150px);
  background-color: white;
  display: flex;
  visibility: ${props => (props.removeBlock ? "hidden" : "visible")};
  pointer-events: ${props => (props.removeBlock ? "none" : "default")};
  flex-direction: column;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 20px;

  @media (max-width: 500px) {
    width: 90%;
    left: 5%;
  }
`

const OrderTypeTopbar = styled.div`
  width: 100%;
  padding: 0 5px 15px 0;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #8696b5;
`

const OrderTypeHeading = styled.p`
  font-family: "GFSDidot-Regular";
  font-size: 32px;
  color: black;
  margin: 0;
`

const OrderTypeCloseImg = styled.img`
  width: 20px;
  cursor: pointer;
`

const SkipContainer = styled.a`
  border: 1px solid black;
  margin: 10px 0 20px 0;
  padding: 10px;
  box-sizing: border-box;
`

const SkipImg = styled.img`
  width: 100%;
  height: 50px;
  margin: 0;
`

const OrderDirectlyButton = styled.button`
  margin: 20px 0 10px 0;
  height: 70px;
  display: grid;
  place-items: center;
  font-family: "OpenSans-SemiBold";
  font-size: 24px;
  color: black;
  cursor: pointer;
  border: 1px solid black;
  background: none;
`

const Topbar = styled.div`
  width: 100%;
  height: 90px;
  background-color: #8696b5;
  display: flex;
  justify-content: center;
  align-items: center;
`

const TopBarLink = styled.p`
  font-family: "OpenSans-SemiBold";
  font-size: 24px;
  color: white;
  margin: 0 40px 0 0;
  cursor: pointer;

  &:last-child {
    margin: 0;
  }

  @media (max-width: 800px) {
    font-size: 14px;
    margin: 0 20px 0 0;
    max-width: 70px;
  }
`

const LandingContainer = styled.div`
  width: 100%;
  max-height: calc(100vh - 90px);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;

  @media (max-width: 1000px) {
    min-height: calc(90vh - 90px);
  }
`

const LandingImg = styled.img`
  height: 120%;
  width: 100%;
  object-fit: cover;
  opacity: 1;
  @media (max-width: 1000px) {
    height: 120%;
    width: 100%;
    position: absolute;
    top: -9999px;
    bottom: -9999px;
    left: -9999px;
    right: -9999px;
    margin: auto;
  }
  @media (min-width: 1400px) {
    width: 100%;
    height: auto;
  }
`

const LandingLogo = styled.img`
  width: 500px;
  position: absolute;
  z-index: 5;
  @media (max-width: 1000px) {
    width: 55%;
  }
  @media (max-height: 750px) and (min-width: 800px) {
    width: 40%;
  }
  @media (max-height: 650px) and (min-width: 800px) {
    width: 30%;
  }
`

const LandingTitle = styled.h3`
  font-family: "GFSDidot-Regular";
  font-size: 48px;
  color: #7a859d;
  bottom: 20px;
  margin: 0;
  width: 70%;
  text-align: center;
  left: 15%;
  position: absolute;
  z-index: 6;
  @media (max-width: 1200px) {
    font-size: 38px;
  }
  @media (max-height: 650px) and (min-width: 800px) {
    font-size: 30px;
  }
  @media (min-width: 1800px) {
    font-size: 60px;
  }
`

const EventsContainer = styled.div`
  width: 100%;
  background-color: #c1cad8;
  display: grid;
  grid-template-columns: 40% 50%;
  grid-gap: 10%;

  @media (max-width: 1000px) {
    grid-template-columns: 100%;
    grid-gap: 30px;
  }
`

const EventsTitleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: 75px 0 0 0;

  @media (max-width: 1000px) {
    align-items: center;
  }
`

const EventTitle = styled.h1`
  font-family: "GFSDidot-Regular";
  font-size: 48px;
  color: white;
  margin: 0;

  @media (max-width: 1100px) {
    font-size: 36px;
  }

  @media (max-width: 1000px) {
    font-size: 24px;
  }
`

const EventTitleDivider = styled.div`
  width: 405px;
  height: 2px;
  background-color: white;
  margin: 10px 0 0 0;

  @media (max-width: 1100px) {
    width: 305px;
  }

  @media (max-width: 1000px) {
    width: 202px;
  }
`

const EventsListContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 85px 0 75px 0;

  @media (max-width: 1000px) {
    margin: 0px 0 50px 0;
    align-items: center;
  }
`

const EventWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 0 30px 0;

  @media (max-width: 1000px) {
    align-items: center;
  }
`

const EventDate = styled.p`
  font-family: "GFSDidot-Regular";
  font-size: 24px;
  color: white;
  margin: 0 0 10px 0;

  @media (max-width: 1000px) {
    font-size: 18px;
  }
`

const EventDesc = styled.p`
  font-family: "OpenSans-SemiBold";
  font-size: 14px;
  color: white;
  text-transform: uppercase;
  margin: 0;

  @media (max-width: 1000px) {
    font-size: 14px;
  }
`

const DinnerContainer = styled.div`
  width: 100%;
  max-height: calc(100vw / 2);
  display: flex;
  position: relative;
  overflow: hidden;
  @media (max-width: 1000px) {
    min-height: 600px;
  }
`
const DinnerImg = styled.img`
  width: 100%;
  object-fit: cover;
  @media (max-width: 1000px) {
    height: 120%;
    position: absolute;
    top: -9999px;
    bottom: -9999px;
    left: -9999px;
    right: -9999px;
    margin: auto;
  }
`
const DinnerMenuContainer = styled.div`
  position: absolute;
  width: 600px;
  height: fit-content;
  left: 0;
  top: 22.5%;
  background-color: rgba(134, 150, 181, 0.9);
  display: flex;
  align-items: center;
  flex-direction: column;
  @media (max-width: 1200px) {
    height: fit-content;
    top: 17.5%;
  }
  @media (max-width: 1000px) {
    width: 90%;
    height: fit-content;
    top: 30px;
    left: 5%;
  }
`
const DinnerMenuSmallTitle = styled.p`
  font-family: "GFSDidot-Regular";
  font-size: 14px;
  color: white;
  margin: 30px 0 5px 0;
  @media (max-width: 1000px) {
    font-size: 12px;
    margin: 20px 0 5px 0;
  }
`
const DinnerMenuTitle = styled.p`
  font-family: "GFSDidot-Regular";
  font-size: 54px;
  color: white;
  margin: 30px 0 5px 0;
  @media (max-width: 1000px) {
    font-size: 32px;
    margin: 20px 0 5px 0;
    text-align: center;
  }
`
const DinnerMenuTime = styled.p`
  font-family: "GFSDidot-Regular";
  font-size: 14px;
  color: white;
  margin: 0 0 10px 0;
  @media (max-width: 1000px) {
    font-size: 12px;
  }
`
const DinnerMenuDivider = styled.div`
  width: 200px;
  min-height: 2px;
  background: white;
  margin: 0 0 20px 0;
  @media (max-width: 1000px) {
    width: 150px;
  }
`
const DinnerMenuDesc = styled.p`
  font-family: "OpenSans-SemiBold";
  font-size: 18px;
  color: white;
  width: 60%;
  margin: 0 0 30px 0;
  text-align: center;
  text-justify: center;
  @media (max-width: 1000px) {
    font-size: 14px;
    width: 80%;
    margin: 0 0 20px 0;
  }
`
const DinnerMenuButton = styled.button`
  outline: none;
  text-decoration: none;
  border: none;
  width: 150px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "OpenSans-Regular";
  font-size: 16px;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  background-color: #1f2923;
  margin: 0 0 30px 0;
  @media (max-width: 1000px) {
    font-size: 14px;
  }
`
const MenuButton = styled.button`
  outline: none;
  text-decoration: none;
  border: none;
  width: 150px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "OpenSans-Regular";
  font-size: 16px;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  background-color: #1f2923;
  margin: 0 0 30px 0;
  @media (max-width: 1000px) {
    font-size: 14px;
  }
`
const DinnerItemContainer = styled.div`
  position: absolute;
  width: 350px;
  height: fit-content;
  right: 0;
  top: 55%;
  background-color: rgba(134, 150, 181, 0.9);
  display: flex;
  align-items: center;
  flex-direction: column;
  @media (max-width: 1300px) {
    height: fit-content;
    top: 50%;
  }
  @media (max-width: 1000px) {
    width: 90%;
    height: fit-content;
    top: 350px;
    left: 5%;
  }
`
const DinnerItemTitle = styled.p`
  font-family: "GFSDidot-Regular";
  font-size: 32px;
  color: white;
  margin: 30px 0 5px 0;
  @media (max-width: 1000px) {
    font-size: 20px;
    margin: 20px 0 5px 0;
  }
`
const DinnerItemPrice = styled.p`
  font-family: "GFSDidot-Regular";
  font-size: 14px;
  color: white;
  margin: 0 0 10px 0;
  @media (max-width: 1000px) {
    font-size: 12px;
  }
`
const DinnerItemDesc = styled.p`
  font-family: "OpenSans-SemiBold";
  font-size: 16px;
  color: white;
  width: 70%;
  margin: 0 0 30px 0;
  text-align: center;
  text-justify: center;
  @media (max-width: 1200px) {
    font-size: 14px;
  }
`
const LunchContainer = styled.div`
  width: 100%;
  display: flex;
  max-height: calc(100vw / 2);
  position: relative;
  overflow: hidden;
  @media (max-width: 1000px) {
    min-height: 600px;
  }
`
const LunchImg = styled.img`
  width: 100%;
  object-fit: cover;
  @media (max-width: 1000px) {
    height: 120%;
    position: absolute;
    top: -9999px;
    bottom: -9999px;
    left: -9999px;
    right: -9999px;
    margin: auto;
  }
`
const LunchMenuContainer = styled.div`
  position: absolute;
  width: 600px;
  height: fit-content;
  right: 0;
  top: 22.5%;
  background-color: rgba(134, 150, 181, 0.9);
  display: flex;
  align-items: center;
  flex-direction: column;
  @media (max-width: 1200px) {
    height: fit-content;
    top: 17.5%;
  }
  @media (max-width: 1000px) {
    width: 90%;
    height: fit-content;
    top: 30px;
    left: 5%;
  }
`
const LunchItemContainer = styled.div`
  position: absolute;
  width: 350px;
  height: fit-content;
  left: 0;
  top: 55%;
  background-color: rgba(134, 150, 181, 0.9);
  display: flex;
  align-items: center;
  flex-direction: column;
  @media (max-width: 1300px) {
    height: fit-content;
    top: 50%;
  }
  @media (max-width: 1000px) {
    width: 90%;
    height: fit-content;
    top: 350px;
    left: 5%;
  }
`

const ConceptContainer = styled.div`
  width: 100%;
  max-height: calc(100vw / 2);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;

  @media (max-width: 1000px) {
    min-height: 300px;
  }
`

const ConceptImg = styled.img`
  width: 100%;
  object-fit: cover;

  @media (max-width: 1000px) {
    height: 120%;
    width: 100%;
    position: absolute;
    top: -9999px;
    bottom: -9999px;
    left: -9999px;
    right: -9999px;
    margin: auto;
  }
`

const ConceptWrapper = styled.div`
  position: absolute;
  width: 80%;
  display: flex;
  align-items: center;
  flex-direction: column;
  left: 10%;
  top: 100px;
  z-index: 5;

  @media (max-width: 1200px) {
    top: 50px;
  }

  @media (max-width: 1000px) {
    width: 90%;
    left: 5%;
    top: 30px;
  }
`

const ConceptSubTitle = styled.p`
  font-family: "GFSDidot-Regular";
  font-size: 36px;
  color: #050505;
  margin: 0 0 5px 0;

  @media (max-width: 1000px) {
    font-size: 14px;
  }

  @media (max-width: 600px) {
    font-size: 12px;
  }
`

const ConceptTitle = styled.h1`
  font-family: "GFSDidot-Regular";
  font-size: 72px;
  color: #050505;
  margin: 0 0 10px 0;

  @media (max-width: 1000px) {
    font-size: 32px;
  }

  @media (max-width: 600px) {
    font-size: 24px;
  }
`

const ConceptDivider = styled.div`
  width: 200px;
  min-height: 2px;
  background: #050505;
  margin: 0 0 20px 0;

  @media (max-width: 1000px) {
    width: 150px;
  }

  @media (max-width: 600px) {
    width: 125px;
  }

  @media (min-width: 2000px) {
    margin: 0 0 50px 0;
  }
`

const ConceptDesc = styled.div`
  font-family: "OpenSans-SemiBold";
  font-size: 22px;
  color: #050505;
  width: 70%;
  text-align: center;
  text-justify: center;
  margin: 0 0 20px 0;

  @media (max-width: 1000px) {
    font-size: 14px;
  }

  @media (max-width: 800px) {
    font-size: 12px;
    width: 95%;
  }

  @media (min-width: 2000px) {
    font-size: 30px;
    width: 60%;
  }
`

const MapContainer = styled.div`
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`

const MapContactContainer = styled.div`
  position: absolute;
  width: 500px;
  height: 60%;
  right: 0;
  top: 15%;
  background-color: rgba(134, 150, 181, 0.9);
  display: flex;
  align-items: center;
  flex-direction: column;

  @media (max-width: 1000px) {
    display: none;
  }
`

const MapContactTitle = styled.p`
  font-family: "GFSDidot-Regular";
  font-size: 48px;
  color: white;
  margin: 30px 0 5px 0;
`

const MapContactLocation = styled.p`
  font-family: "GFSDidot-Regular";
  font-size: 14px;
  color: white;
  margin: 0 0 10px 0;
`

const MapContactDivider = styled.div`
  width: 155px;
  min-height: 2px;
  background: white;
  margin: 0 0 10px 0;
`

const MapContactHoursWrapper = styled.div`
  display: grid;
  grid-template-columns: 60% 40%;
  width: 40%;
  margin-top: 10px;
`

const MapContactHoursText = styled.p`
  font-family: "OpenSans-SemiBold";
  font-size: 14px;
  color: white;
  margin: 0 0 10px 0;
`

const MapContactDesc = styled.p`
  font-family: "OpenSans-Regular";
  font-size: 14px;
  color: white;
  margin: 0 0 10px 0;
  text-align: center;
  text-justify: center;
`

const Footer = styled.div`
  width: 100%;
  height: 350px;
  background-color: #9aa6bd;
  display: grid;
  box-sizing: border-box;
  padding: 50px;
  grid-template-columns: 60% 26% 14%;

  @media (max-width: 800px) {
    height: fit-content;
    grid-template-columns: 100%;
    grid-gap: 20px 0;
    padding: 40px 20px;
  }
`

const FooterLeftContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const FooterNav = styled.div`
  display: flex;
  width: 100%;

  @media (max-width: 1200px) {
    display: grid;
    grid-template-columns: 25% 25% 50%;
    grid-gap: 10px 0;
  }
`

const FooterNavLink = styled.p`
  font-family: "OpenSans-Bold";
  font-size: 16px;
  color: white;
  border-right: 2px solid white;
  padding: 0 20px 0 20px;
  height: 25px;
  margin: 0;
  cursor: pointer;

  &:last-child {
    border-right: none;
  }

  @media (max-width: 1200px) {
    font-size: 14px;
    padding: 0;
    border: none;
    height: auto;
  }
`

const FooterCredit = styled.p`
  font-family: "OpenSans-Bold";
  font-size: 14px;
  color: white;
  margin: 0 0 0 80px;

  @media (max-width: 800px) {
    display: none;
  }
`

const FooterMiddleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const FooterTitle = styled.p`
  font-family: "OpenSans-Bold";
  font-size: 18px;
  color: white;
  margin: 0 0 10px 0;
`

const FooterLocationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 10px 0;
`

const FooterLocation = styled.p`
  font-family: "OpenSans-Bold";
  font-size: 12px;
  color: white;
  margin: 0 0 5px 0;
`

const FooterContactWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 10px 0;
`

const FooterContact = styled.p`
  font-family: "OpenSans-Bold";
  font-size: 12px;
  color: white;
  margin: 0 0 5px 0;
`

const FooterHoursWrapper = styled.div`
  display: grid;
  grid-template-columns: 80px 80px;
  grid-gap: 5px;
  margin: 5px 0 0 0;
`

const FooterHours = styled.p`
  font-family: "OpenSans-Bold";
  font-size: 12px;
  color: #e3e4e4;
  margin: 0 0 0 0;
`

const FooterRightContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const FooterFollow = styled.p`
  font-family: "OpenSans-Regular";
  font-size: 18px;
  color: white;
  margin: 0 0 20px 0;
`

const FooterSocialWrapper = styled.div`
  display: flex;
`

const SocialiconLinkWrapper = styled.a`
  text-decoration: none;
  margin: 0 20px 0 0;
`

const SocialIcon = styled.img`
  height: 25px;
  margin: 0;
`

const SliderContainer = styled.div`
  position: relative;
  width: 100%;
  height: 90vh;
  display: flex;
  align-items: center;
  @media (max-width: 500px) {
    height: 250px;
  }
`
const LeftArrowImg = styled.img`
  position: absolute;
  width: 30px;
  left: 30px;
  z-index: 3;
  cursor: pointer;
  opacity: ${props => (props.sliderPos === 3 ? "0.5" : "1")};
  transition: opacity 0.25s ease;
  @media (max-width: 500px) {
    width: 20px;
  }
`
const RightArrowImg = styled.img`
  position: absolute;
  width: 30px;
  right: 30px;
  z-index: 3;
  cursor: pointer;
  opacity: ${props => (props.sliderPos === 1 ? "0.5" : "1")};
  transition: opacity 0.25s ease;
  @media (max-width: 500px) {
    width: 20px;
  }
`
const Slide = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background-size: cover;
  background-repeat: no-repeat;
  top: 0;
`
const Slide1 = styled(Slide)`
  transform: translateX(-100%);
  background-image: url(${MOMO1});
`
const Slide2 = styled(Slide)`
  transform: translateX(0);
  background-image: url(${MOMO2});
`
const Slide3 = styled(Slide)`
  transform: translateX(100%);
  background-image: url(${MOMO3});
`

const QueenBathurstPage = ({ data }) => {
  const [removeBlock, setRemoveBlock] = useState(true)
  const [showMenu, setShowMenu] = useState(false)
  const [showOrderModal, setShowOrderModal] = useState(false)

  useEffect(() => {
    const script = document.createElement("script")

    script.src = "https://www.fbgcdn.com/embedder/js/ewm2.js"
    script.async = true
    script.defer = true

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  useEffect(() => {
    if (typeof window !== "undefined") {
      let image1 = document.getElementById("landingContainer")
      new simpleParallax(image1, {
        scale: 1.2,
      })

      let image2 = document.getElementById("dinnerContainer")
      new simpleParallax(image2, {
        scale: 1.2,
      })

      let image3 = document.getElementById("lunchContainer")
      new simpleParallax(image3, {
        scale: 1.2,
      })

      let image4 = document.getElementById("conceptContainer")
      new simpleParallax(image4, {
        scale: 1.2,
      })
    }
  }, [])

  const handleScroll = elmtId => {
    let elmnt = document.getElementById(elmtId)
    elmnt.scrollIntoView()
  }

  const momoEvents = data.allMarkdownRemark.nodes
    .filter(node => node.frontmatter.title !== "Default")
    .map(event => {
      return (
        <EventWrapper
          key={`event-${event.frontmatter.date}-${event.frontmatter.title}`}
        >
          <EventDate>{event.frontmatter.date}</EventDate>
          <EventDesc>{event.frontmatter.title}</EventDesc>
        </EventWrapper>
      )
    })

  const toggleOverlay = () => {
    setRemoveBlock(prev => !prev)
  }

  const toggleMenu = () => {
    toggleOverlay()
    setShowMenu(prev => !prev)
  }

  const toggleOrderModal = () => {
    toggleOverlay()
    setShowOrderModal(prev => !prev)
  }

  const [sliderPos, setSliderPos] = useState(2)
  const [isAnimating, setIsAnimating] = useState(false)
  const moveLeft = () => {
    if (sliderPos === 3 || isAnimating) {
      return
    } else {
      gsap.to(".slide", {
        xPercent: "-=100",
        duration: 0.25,
        ease: "power2.out",
        onStart: () => {
          setIsAnimating(true)
        },
        onComplete: () => {
          setIsAnimating(false)
        },
      })
      setSliderPos(prev => prev + 1)
    }
  }
  const moveRight = () => {
    if (sliderPos === 1 || isAnimating) {
      return
    } else {
      gsap.to(".slide", {
        xPercent: "+=100",
        duration: 0.25,
        ease: "power2.out",
        onStart: () => {
          setIsAnimating(true)
        },
        onComplete: () => {
          setIsAnimating(false)
        },
      })
      setSliderPos(prev => prev - 1)
    }
  }

  return (
    <>
      <Helmet
        link={[
          {
            rel: "icon",
            type: "image/png",
            sizes: "16x16",
            href: `${favicon16}`,
          },
          {
            rel: "icon",
            type: "image/png",
            sizes: "32x32",
            href: `${favicon32}`,
          },
          {
            rel: "icon",
            type: "image/png",
            sizes: "64x64",
            href: `${favicon64}`,
          },
        ]}
      >
        <title>The MoMo House | Queen & Bathurst</title>
      </Helmet>
      <BlockadeOverlay
        removeBlock={removeBlock}
        onClick={
          showMenu
            ? toggleMenu
            : showOrderModal
            ? toggleOrderModal
            : toggleOverlay
        }
      ></BlockadeOverlay>
      <BackContainer removeBlock={!showMenu}>
        <BackButton onClick={toggleMenu}>Close</BackButton>
      </BackContainer>
      <Blockade removeBlock={!showMenu}>
        <MenuImg src={Menu}></MenuImg>
      </Blockade>
      <OrderTypeContainer removeBlock={!showOrderModal}>
        <OrderTypeTopbar>
          <OrderTypeHeading>Order</OrderTypeHeading>
          <OrderTypeCloseImg src={CloseImg} onClick={toggleOrderModal} />
        </OrderTypeTopbar>
        <OrderDirectlyButton
          data-glf-cuid="53c546cd-d484-4aec-ac20-a267bc4a0e9c"
          data-glf-ruid="6be4bd2d-edc7-41e4-9059-bc40c85d9de9"
          href="https://www.gloriafood.com/admin/public/website-install?d=VGhlJTIwTW9tbyUyMEhvdXNlJTIwUXVlZW4lMjBTdHwtfDZiZTRiZDJkLWVkYzctNDFlNC05MDU5LWJjNDBjODVkOWRlOXwtfDUzYzU0NmNkLWQ0ODQtNGFlYy1hYzIwLWEyNjdiYzRhMGU5Y3wtfFNlZSUyME1FTlUlMjAlMjYlMjBPcmRlcg%3D%3D"
        >
          Order Via Website
        </OrderDirectlyButton>
        <SkipContainer
          href="https://www.skipthedishes.com/the-momo-house-queen-street"
          target="_blank"
        >
          <SkipImg
            src="https://prep.skipthedishes.com/hubfs/weblinks/en/horizontal/orange.svg"
            alt="SkipTheDishes Logo"
          />
        </SkipContainer>
      </OrderTypeContainer>
      <Topbar>
        <TopBarLink
          onClick={() => {
            handleScroll("dinner")
          }}
        >
          Menu
        </TopBarLink>
        <TopBarLink
          onClick={() => {
            handleScroll("map")
          }}
        >
          Location
        </TopBarLink>
        <TopBarLink
          onClick={() => {
            handleScroll("lunch")
          }}
        >
          Online Ordering
        </TopBarLink>
        <TopBarLink
          onClick={() => {
            handleScroll("contact")
          }}
        >
          Contact
        </TopBarLink>
      </Topbar>
      <LandingContainer>
        <LandingLogo src={Logo} />
        <LandingTitle>Queen & Bathurst</LandingTitle>
        <LandingImg id="landingContainer" src={LandingBanner} />
      </LandingContainer>
      <EventsContainer>
        <EventsTitleContainer>
          <EventTitle>NEWS & EVENTS</EventTitle>
          <EventTitleDivider></EventTitleDivider>
        </EventsTitleContainer>
        <EventsListContainer>
          {data.allMarkdownRemark.nodes.length > 1 ? (
            momoEvents
          ) : (
            <EventWrapper>
              <EventDesc>No upcoming events</EventDesc>
            </EventWrapper>
          )}
        </EventsListContainer>
      </EventsContainer>
      <SliderContainer>
        <LeftArrowImg
          src={LeftArrow}
          onClick={moveLeft}
          sliderPos={sliderPos}
        ></LeftArrowImg>
        <RightArrowImg
          src={RightArrow}
          onClick={moveRight}
          sliderPos={sliderPos}
        ></RightArrowImg>
        <Slide1 className="slide"></Slide1>
        <Slide2 className="slide"></Slide2>
        <Slide3 className="slide"></Slide3>
      </SliderContainer>
      <DinnerContainer id="dinner">
        <DinnerImg src={DinnerBanner} id="dinnerContainer"></DinnerImg>
        <DinnerMenuContainer>
          <DinnerMenuTitle>MENU</DinnerMenuTitle>
          <DinnerMenuTime>1:00PM-9:00PM</DinnerMenuTime>
          <DinnerMenuDivider></DinnerMenuDivider>
          <DinnerMenuDesc>Click below to view our menu.</DinnerMenuDesc>
          <DinnerMenuButton onClick={toggleOrderModal}>MENU</DinnerMenuButton>
        </DinnerMenuContainer>
        <DinnerItemContainer>
          <DinnerItemTitle>BUTTER CHICKEN</DinnerItemTitle>
          <DinnerMenuDivider></DinnerMenuDivider>
          <DinnerItemDesc>
            Momos with butter chicken sauce. Tibetan and Indian fusion.
          </DinnerItemDesc>
        </DinnerItemContainer>
      </DinnerContainer>
      <LunchContainer id="lunch">
        <LunchImg id="lunchContainer" src={LunchBanner} />
        <LunchMenuContainer>
          <DinnerMenuTitle>ONLINE ORDERING</DinnerMenuTitle>
          <DinnerMenuTime>1:00PM-9:00PM</DinnerMenuTime>
          <DinnerMenuDivider></DinnerMenuDivider>
          <DinnerMenuDesc>
            Click below to place an order online for pickup.
          </DinnerMenuDesc>
          <MenuButton onClick={toggleOrderModal}>ORDER NOW</MenuButton>
        </LunchMenuContainer>
        <LunchItemContainer>
          <DinnerItemTitle>CHAT</DinnerItemTitle>
          <DinnerMenuDivider></DinnerMenuDivider>
          <DinnerItemDesc>
            Momos with tamarind, dry chips, and cream.
          </DinnerItemDesc>
        </LunchItemContainer>
      </LunchContainer>
      <ConceptContainer id="concept">
        <ConceptWrapper>
          <ConceptSubTitle>MOMO</ConceptSubTitle>
          <ConceptTitle>CONCEPT</ConceptTitle>
          <ConceptDivider></ConceptDivider>
          <ConceptDesc>
            From Garab Serdok, the creator of Tibet Kitchen, The Momo House
            focuses on momo - a type of Tibetan dumpling. The Momo House
            specializes in Himalyan cuisine while providing a warm and inviting
            atmosphere. We pride ourselves in our commitment to quality food and
            guest satisfaction.
          </ConceptDesc>
          <ConceptDesc>
            Visit today at try some of our delicious momos today!
          </ConceptDesc>
        </ConceptWrapper>
        <ConceptImg src={ConceptBanner} id="conceptContainer"></ConceptImg>
      </ConceptContainer>
      <MapContainer id="map">
        <MapContactContainer>
          <MapContactTitle>CONTACT</MapContactTitle>
          <MapContactLocation>685 QUEEN ST WEST</MapContactLocation>
          <MapContactDivider></MapContactDivider>
          <MapContactDesc>(416) 588-8887</MapContactDesc>
          <MapContactHoursWrapper>
            <MapContactHoursText>Mon - Thurs:</MapContactHoursText>
            <MapContactHoursText>1pm - 9pm</MapContactHoursText>
          </MapContactHoursWrapper>
          <MapContactHoursWrapper>
            <MapContactHoursText>Fri - Sunday:</MapContactHoursText>
            <MapContactHoursText>1pm - 9pm</MapContactHoursText>
          </MapContactHoursWrapper>
        </MapContactContainer>
        <iframe
          width="100%"
          height="500"
          frameBorder="0"
          src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJC5KOLOc0K4gRd2PvNbfJJIw&key=AIzaSyBAihw2W0zDlOuR0BwL-cVG1UbUfbaeT-Y"
          allowFullScreen
        ></iframe>
      </MapContainer>
      <Footer id="contact">
        <FooterLeftContainer>
          <FooterNav>
            <FooterNavLink
              onClick={() => {
                handleScroll("dinner")
              }}
            >
              Menu
            </FooterNavLink>
            <FooterNavLink
              onClick={() => {
                handleScroll("map")
              }}
            >
              Location
            </FooterNavLink>
            <FooterNavLink
              onClick={() => {
                handleScroll("lunch")
              }}
            >
              Online Ordering
            </FooterNavLink>
            <FooterNavLink
              onClick={() => {
                handleScroll("contact")
              }}
            >
              Contact
            </FooterNavLink>
            <FooterNavLink
              onClick={() => {
                handleScroll("concept")
              }}
            >
              About
            </FooterNavLink>
          </FooterNav>
          <FooterCredit>Made with 🌱 by Tarro</FooterCredit>
        </FooterLeftContainer>
        <FooterMiddleContainer>
          <FooterTitle>Momo House Queen & Bathurst</FooterTitle>
          <FooterLocationWrapper>
            <FooterLocation>685 Queen St W</FooterLocation>
          </FooterLocationWrapper>
          <FooterContactWrapper>
            <FooterContact>T: (416) 551-6788</FooterContact>
            <FooterContact>E: info@themomohouse.ca</FooterContact>
          </FooterContactWrapper>
          <FooterHours>Hours</FooterHours>
          <FooterHoursWrapper>
            <FooterHours>Mon - Wed:</FooterHours>
            <FooterHours>1pm - 9pm</FooterHours>
            <FooterHours>Thurs - Sat:</FooterHours>
            <FooterHours>1pm - 9pm</FooterHours>
            <FooterHours>Sunday:</FooterHours>
            <FooterHours>1pm - 9pm</FooterHours>
          </FooterHoursWrapper>
        </FooterMiddleContainer>
        <FooterRightContainer>
          <FooterFollow>Follow us</FooterFollow>
          <FooterSocialWrapper>
            <SocialiconLinkWrapper
              target="_blank"
              href="https://www.facebook.com/TheMoMoHouse.ca/"
            >
              <SocialIcon src={Facebook} />
            </SocialiconLinkWrapper>
            <SocialiconLinkWrapper
              target="_blank"
              href="https://www.instagram.com/themomohouse.ca/?hl=en"
            >
              <SocialIcon src={Ig} />
            </SocialiconLinkWrapper>
          </FooterSocialWrapper>
        </FooterRightContainer>
      </Footer>
    </>
  )
}

export default QueenBathurstPage

export const query = graphql`
  query {
    allMarkdownRemark {
      nodes {
        frontmatter {
          date
          title
        }
      }
    }
  }
`
